exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-digitalexportmanager-js": () => import("./../../../src/templates/digitalexportmanager.js" /* webpackChunkName: "component---src-templates-digitalexportmanager-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-ricercheanalisimercato-js": () => import("./../../../src/templates/ricercheanalisimercato.js" /* webpackChunkName: "component---src-templates-ricercheanalisimercato-js" */),
  "component---src-templates-tumulti-issue-js": () => import("./../../../src/templates/tumultiIssue.js" /* webpackChunkName: "component---src-templates-tumulti-issue-js" */),
  "component---src-templates-tumulti-js": () => import("./../../../src/templates/tumulti.js" /* webpackChunkName: "component---src-templates-tumulti-js" */)
}

