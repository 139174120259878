const theme = {
  colors: {
    text: "#131E26",
    background: "#ffffff",
    lightBackground: "#fafafa",
    primary: "#00a889",
    secondary: "#00a889",
    light: "#ffffff",
    dark: "#131E26",
    lightGrey: "#999999",
    lightLightGrey: "#C6C6C6",
  },
  fonts: {
    body: 'neue-haas-grotesk-display, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      'neue-haas-grotesk-display, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    serif: '"Signifier", Georgia, serif',
  },
  space: [0, 4, 8, 16, 32, 48, 64, 96, 128, 192, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 28, 36, 48, 64, 80, 128, 192, 256, 512],
  fontWeights: {
    body: 300,
    heading: 500,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  sizes: {
    container: 1280,
  },
  radii: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },
  text: {
    default: {
      lineHeight: "body",
    },

    heading: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      letterSpacing: "-.5px",
    },

    h1: {
      fontSize: [6, 7],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
    },
    h2: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "dark",
      mb: 3,
      mt: 4,
    },
    h3: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h4: {
      fontSize: 4,
      fontWeight: "bold",
      color: "primary",
      mb: 2,
      mt: 4,
    },
    h5: {
      fontSize: 4,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    h6: {
      fontSize: 3,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    caption: {
      fontSize: 1,
      color: "lightGrey",
      fontWeight: "bold",
    },
    article: {
      fontSize: 3,
      lineHeight: 1.5,
      fontFamily: "serif",
    },
    blockquote: {
      fontSize: 3,
      lineHeight: 1.5,
      ml: [4, 6],
      p: {
        fontFamily: "heading",
        fontWeight: "bold",
      },
    },
  },
  layout: {
    container: {
      padding: [3, 4],
    },
    sm: {
      maxWidth: "720px",
      padding: [3, 4],
    },
    md: {
      maxWidth: "1020px",
      padding: [3, 4],
    },
    fw: {
      maxWidth: "100%",
      padding: [3, 4],
    },
    custom: {
      maxWidth: [
        "calc(((100% - 750px) / 2) + 750px) ",
        "calc(((100% - 970px) / 2) + 970px)",
        "calc(((100% - 1280px) / 2) + 1280px)",
      ],
      padding: [3, 4],
    },
    header: {
      maxWidth: "100%",
    },
  },

  inputs: {
    primary: {
      border: "none",
      borderBottom: "1px solid",
      borderColor: "dark",
      borderRadius: "none",
      py: 3,
      outline: "none",
      color: "light",
    },
  },
  buttons: {
    primary: {
      color: "primary",
      bg: "transparent",
      border: "2px solid",
      borderRadius: "none",
      fontSize: 4,
      fontWeight: "bold",
      px: 5,
      py: 2,
      cursor: "pointer",
      "&:hover": {
        bg: "primary",
        color: "light",
      },
    },
    secondary: {
      color: "dark",
      bg: "transparent",
      border: "2px solid",
      borderRadius: "none",
      fontSize: 4,
      fontWeight: "bold",
      px: 5,
      py: 2,
      cursor: "pointer",
      "&:hover": {
        bg: "dark",
        color: "light",
      },
    },
    secondaryLight: {
      color: "light",
      bg: "transparent",
      border: "2px solid",
      borderRadius: "none",
      fontSize: 4,
      fontWeight: "bold",
      px: 5,
      py: 2,
      cursor: "pointer",
      "&:hover": {
        bg: "light",
        color: "primary",
      },
    },
  },
  links: {
    nav: {
      paddingX: 3,
      paddingY: 3,
      backgroundColor: "primary",
      "&.active": {
        color: "primary",
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "bold",
      },
    },
  },
  styles: {
    root: {
      // "*::-moz-selection": {
      //   /* Code for Firefox */ color: "light",
      //   backgroundColor: "primary",
      // },
      // "*::selection": {
      //   color: "light",
      //   backgroundColor: "primary",
      // },
      fontFamily: "body",
      fontWeight: "body",
      a: {
        color: "primary",
        "&:hover": {
          textDecoration: "none",
        },
      },
      "--swiper-theme-color": "#00a889",
      ".swiper-container": { pb: 5 },
      ".oneSide": {
        p: [3, 4],
        py: [0, 0, 0, 0],
        pr: [0, 0, 0, 0],
        mr: [0, 0, 0, 0],
        ml: [" calc(50vw - 375px)", "calc(50vw - 485px)", "calc(50vw - 640px)"],
      },
    },
  },
}

export default theme
